import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createDesktopCss } from '~/css/createDesktopCss';
import { fontWeight200, fontWeight600 } from '~/css/font';
import { flex } from '~/modules/AppLayout/FlexGridCss';
export const ChartCss = css `
  width: 100%;
  border: 1px solid ${'#555555'};
  height: 500px;
  ${createDesktopCss(css `
    height: 100%;
  `)}
`;
export const SideQuoteCss = css `
  ${flex.v.default};
  background-color: #202020;
  height: 100%;
  width: 100%;
`;
export const IndicatorsButton = styled.button `
  ${flex.h.allCenter};
  cursor: pointer;
  width: 100px;
  font-size: 18px;
  ${fontWeight600};
  color: #ff0000;
  margin: 5px;
  background-color: #ffff00;
`;
export const QuoteBoxElement = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 180px;
`;
export const SwitchModeElement = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 40px;
  margin: 1% 0% 1% 0%;
`;
export const SwitchModeButton = styled.div `
  ${flex.h.allCenter};
  width: 50%;
  height: 30px;
  border-radius: 5px;
  background-color: ${props => (props.color ? props.color : '#4f525c')};
  border: 1px solid ${'#aaaaaa'};
  margin: 1% 1% 1% 1%;
  cursor: pointer;
  &:hover {
    border: 1px solid ${'#ffffff'};
  }
`;
export const SwitchTESAButton = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: #555555;
  border-radius: 5px;
  font-size: 16px;
  ${fontWeight600};
  border: 1px solid ${'#aaaaaa'};
  &:hover {
    border: 1px solid ${'#ffffff'};
  }
`;
export const IndicatorsButtonName = styled.button `
  ${flex.h.allCenter};
  cursor: pointer;
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: 18px;
  ${fontWeight600};
  color: #ffffff;
  border: 1px solid ${'#aaaaaa'};
  background-color: #555555;
  &:hover {
    border: 1px solid ${'#ffffff'};
  }
`;
export const InfoTop = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: auto;
  font-size: 26px;
  color: #ffffff;
  background-color: #000055;
`;
export const InfoMode = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 5 %;
  font-size: 18px;
  color: #ffff00;
  background-color: #000055;
`;
export const InfoTitle = styled.div `
  ${flex.h.default};
  justify-content: left;
  align-items: left;
  padding: 10px 0px 0px 10px;
  width: 100%;
  font-size: 18px;
  color: '#ffffff';
`;
export const TargetTitle = styled.div `
  ${flex.h.default};
  justify-content: left;
  align-items: left;
  margin: 5px 0px 0px 15px;
  width: 100%;
  font-size: 18px;
  color: '#ffffff';
`;
export const InfoItemText = styled.div `
  ${flex.h.default};
  width: 35%;
  font-size: 18px;
  color: '#ffffff';
`;
export const InfoText = styled.div `
  ${flex.h.allCenter};
  width: 65%;
  margin: 0px 20px 0px 10px;
  border-radius: 5px;
  font-size: 18px;
  color: '#ffffff';
  border: 1px solid ${'#555555'};
  background-color: #333333;
`;
export const InfoMpText = styled.div `
  ${flex.h.allCenter};
  width: 65%;
  margin: 0px 20px 0px 10px;
  border-radius: 5px;
  font-size: 18px;
  color: '#ffffff'
  border: 1px solid ${'#555555'};;
  background-color: ${props => props.color};
`;
//--------------------------------------
/** 權值股切換按鈕 */
export const MainStockBotton = styled.div `
  ${flex.h.crossCenter};
  width: 100%;
  height: 50px;
  margin: 1px 0px 1px 0px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #222222;
  &:hover {
    background-color: #444444;
  }
`;
export const MainStockName = styled.div `
  ${flex.v.crossCenter};
  width: 30%;
  font-size: 15px;
  border-radius: 5px;
  background-color: #333333;
  margin: 0px 10px 0px 10px;
`;
export const MultipleChartsBox = styled.div `
  width: 50%;
  height: 100%;
  padding: 5px;
`;
export const QuoteNow = styled.div `
  ${flex.inline.default};
  justify-content: left;
  align-items: center;
  margin-left: 1px;
  width: 40%;
`;
export const QuoteChange = styled.div `
  ${flex.inline.allCenter};
  width: 20%;
`;
export const QuoteChangePer = styled.div `
  ${flex.inline.allCenter};
  width: 40%;
`;
//--------------------------------------
/** 顯示當前商品 */
export const CurrentSymbolInfo = styled.div `
  ${flex.h.allCenter};
  width: auto;
  height: 30px;
  font-size: 24px;
  ${fontWeight200};
  border-radius: 5px;
  background-color: #333333;
`;
