import { css } from '@emotion/react';
import React, { memo } from 'react';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { InfoTitle, InfoText, InfoMpText, InfoItemText } from '~/pages/blue888/modules/blue168Css';
export const HoldingCostPrice = memo(function HoldingCostPrice(props) {
    const entryPrice = useIndicatorStore(state => state.entryPrice) || 0;
    const entryUnixTime = useIndicatorStore(state => state.entryTime) || 0;
    const nowClose = useIndicatorStore(status => status.nowClose) || 0;
    const _symbolNumber = useIndicatorStore(state => state.symbolNumber) || '';
    const entryDate = entryUnixTime === 0 ? 'NaN' : new Date(entryUnixTime).toLocaleDateString();
    const entryTime = entryUnixTime === 0
        ? 'NaN'
        : new Date(entryUnixTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    /** 多空單判別 */
    const isShortHolding = typeof entryPrice === 'number' && entryPrice < 0 ? true : false;
    /** 多空單String */
    const marketPostion = entryPrice === 0 ? 'NaN' : isShortHolding ? '-1' : '+1';
    /** 部位方向 */
    const marketPostionEntryPrice = entryPrice < 0 ? entryPrice * -1 : entryPrice;
    /** 未平倉浮動點數(index) */
    const openPosition = nowClose !== 0 ? (isShortHolding ? (nowClose + entryPrice) * -1 : nowClose - entryPrice) : 0;
    /** 未平倉浮動%數(stock) */
    const percentOpenPosition = nowClose !== 0
        ? isShortHolding
            ? (1 + nowClose / entryPrice) * 1 * 100
            : (nowClose / entryPrice - 1) * 100
        : 1;
    /** 停利1 */
    const target1 = isShortHolding
        ? marketPostionEntryPrice * (1 - 11.17 / 100)
        : marketPostionEntryPrice * (1 + 11.17 / 100);
    /** 停利2 */
    const target2 = isShortHolding
        ? marketPostionEntryPrice * (1 - 19.3 / 100)
        : marketPostionEntryPrice * (1 + 19.3 / 100);
    /** 停利1背景顏色 */
    const targetColor1 = isShortHolding
        ? nowClose <= target1
            ? '#aa9900'
            : '#333333'
        : nowClose >= target1
            ? '#aa9900'
            : '#333333';
    /** 停利2背景顏色 */
    const targetColor2 = isShortHolding
        ? nowClose <= target2
            ? '#aa9900'
            : '#333333'
        : nowClose >= target2
            ? '#aa9900'
            : '#333333';
    /** 商品類別 股票類 與 指數類 */
    const synbolType = _symbolNumber.includes('-') ||
        _symbolNumber.includes('TSEA') ||
        _symbolNumber.includes('TSE') ||
        _symbolNumber.includes('TWS') ||
        _symbolNumber.includes('OTC')
        ? 'index'
        : 'stock';
    /** 股票獲利&數 */
    const stockTarget = (<div>
      {nowClose !== 0 ? (percentOpenPosition < 0 ? '' : '+') : ''}
      {percentOpenPosition.toFixed(2)}%
    </div>);
    /** 指數獲利點數 */
    const indexTarget = (<div>
      {nowClose !== 0 ? (percentOpenPosition < 0 ? '' : '+') : ''}
      {openPosition.toFixed(0)}
    </div>);
    /** 獲利格式 */
    const textTarget = synbolType === 'index' ? '獲利點數' : '獲利%數';
    /** 獲利數值 */
    const valueTarget = synbolType === 'index' ? indexTarget : stockTarget;
    return (<div>
      <InfoTitle>
        <InfoItemText>進場日期 :</InfoItemText>
        <InfoText>{entryDate}</InfoText>
      </InfoTitle>
      <InfoTitle>
        <InfoItemText>進場時間 :</InfoItemText>
        <InfoText>{entryTime}</InfoText>
      </InfoTitle>
      <InfoTitle>
        <InfoItemText>進場成本 :</InfoItemText>
        <InfoMpText color={isShortHolding ? '#33aa33' : '#aa0000'}>
          {marketPostion} @ {marketPostionEntryPrice}
        </InfoMpText>
      </InfoTitle>
      <InfoTitle>
        <InfoItemText>{textTarget} :</InfoItemText>
        <InfoText css={css `
            background-color: #111111;
            color: ${nowClose !== 0 ? (openPosition < 0 ? '#33aa33' : '#ff0000') : '#555555'};
          `}>
          {valueTarget}
        </InfoText>
      </InfoTitle>
      <div css={css `
          margin-top: 5%;
        `}>
        <InfoTitle>
          <InfoItemText>停利點1:</InfoItemText>
          <InfoText css={css `
              background-color: ${targetColor1};
            `}>
            {target1.toFixed(2)}
          </InfoText>
        </InfoTitle>
        <InfoTitle>
          <InfoItemText>停利點2:</InfoItemText>
          <InfoText css={css `
              background-color: ${targetColor2};
            `}>
            {target2.toFixed(2)}
          </InfoText>
        </InfoTitle>
      </div>
    </div>);
});
