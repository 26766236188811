import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const all_day_rainbow = createIndicator({
    displayName: '彩虹',
    id: 'alldayrainbow',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const high_array = this.ohlc.highArray;
            const low_array = this.ohlc.lowArray;
            const high_sma1 = this.PineJS.Std.sma(high_array, 13, this._context);
            const high_sma2 = this.PineJS.Std.sma(high_array, 18, this._context);
            const high_sma3 = this.PineJS.Std.sma(high_array, 23, this._context);
            const high_sma4 = this.PineJS.Std.sma(high_array, 28, this._context);
            const high_sma5 = this.PineJS.Std.sma(high_array, 33, this._context);
            const low_sma1 = this.PineJS.Std.sma(low_array, 13, this._context);
            const low_sma2 = this.PineJS.Std.sma(low_array, 18, this._context);
            const low_sma3 = this.PineJS.Std.sma(low_array, 23, this._context);
            const low_sma4 = this.PineJS.Std.sma(low_array, 28, this._context);
            const low_sma5 = this.PineJS.Std.sma(low_array, 33, this._context);
            const band1_up = high_sma1;
            const band1_dn = low_sma1;
            const band2_up = high_sma2;
            const band2_dn = low_sma2;
            const band3_up = high_sma3;
            const band3_dn = low_sma3;
            const band4_up = high_sma4;
            const band4_dn = low_sma4;
            const band5_up = high_sma5;
            const band5_dn = low_sma5;
            return [
                band1_up,
                band1_dn,
                band2_up,
                band2_dn,
                band3_up,
                band3_dn,
                band4_up,
                band4_dn,
                band5_up,
                band5_dn,
            ];
        },
    },
    metainfo: {
        is_price_study: !0,
        _metainfoVersion: 42,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
            { id: 'plot_3', type: 'line' },
            { id: 'plot_4', type: 'line' },
            { id: 'plot_5', type: 'line' },
            { id: 'plot_6', type: 'line' },
            { id: 'plot_7', type: 'line' },
            { id: 'plot_8', type: 'line' },
            { id: 'plot_9', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_5: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_6: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_7: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_8: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_9: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
            },
            filledAreasStyle: {
                fill_0: { color: '#ff0000', transparency: 80, visible: !0 },
                fill_1: { color: '#ff5500', transparency: 80, visible: !0 },
                fill_2: { color: '#ffff00', transparency: 80, visible: !0 },
                fill_3: { color: '#67cc5b', transparency: 80, visible: !0 },
                fill_4: { color: '#3874cb', transparency: 80, visible: !0 },
            },
            precision: 2,
            inputs: {},
        },
        filledAreas: [
            {
                id: 'fill_0',
                objAId: 'plot_0',
                objBId: 'plot_1',
                type: 'plot_plot',
                title: '彩虹',
            },
            {
                id: 'fill_1',
                objAId: 'plot_2',
                objBId: 'plot_3',
                type: 'plot_plot',
                title: '彩虹',
            },
            {
                id: 'fill_2',
                objAId: 'plot_4',
                objBId: 'plot_5',
                type: 'plot_plot',
                title: '彩虹',
            },
            {
                id: 'fill_3',
                objAId: 'plot_6',
                objBId: 'plot_7',
                type: 'plot_plot',
                title: '彩虹',
            },
            {
                id: 'fill_4',
                objAId: 'plot_8',
                objBId: 'plot_9',
                type: 'plot_plot',
                title: '彩虹',
            },
        ],
        styles: {
            plot_0: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_3: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_4: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_5: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_6: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_7: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_8: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_9: {
                title: '指標',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [],
    },
});
