import { useIndicatorStore, indicatorStoreSetStateDebounced } from '~/store/useIndicatorStore';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 指數、台指、海期訊號 */
export const twse_crazy_c_signal = createIndicator({
    displayName: '狂飆C訊號',
    id: 'twsesignal',
    enabledOn(symbol, data, channel) {
        const state = useIndicatorStore.getState();
        state.symbolChannel = String(data?.type);
        indicatorStoreSetStateDebounced(state);
        /** 訊號只能用在 海期、台指、加權 */
        return symbol.includes('TX') || symbol.includes('TSEA') || data?.type === channel.os_futures;
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const in1 = this._input(0);
            const in2 = this._input(1);
            const time = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const high = this.PineJS.Std.high(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const interval = this._context.symbol?.periodBase;
            const bState = this._context.new_var();
            const pvflag = this._context.new_var();
            const bsentry = this._context.new_var();
            const entryPrice = this._context.new_var();
            const _symbolNumber = this.PineJS.Std.ticker(this._context);
            //日線KD -------------------
            const i = 12;
            const o = 8;
            const r = 6;
            const s = this.PineJS.Std.close(this._context);
            const a = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const c = this._context.new_var(s);
            const u = this._context.new_var(a);
            const h = this._context.new_var(l);
            const d = this.PineJS.Std.stoch(c, u, h, i, this._context);
            const p = this._context.new_var(d);
            const g = this.PineJS.Std.ema(p, o, this._context);
            const f = this._context.new_var(g);
            const KD_K = g;
            const KD_K_array = this._context.new_var(KD_K);
            const KD_D = this.PineJS.Std.ema(f, r, this._context);
            const KD_D_array = this._context.new_var(KD_D);
            //周線KD -------------------
            const wi = 9 * 4;
            const wo = 6 * 4;
            const wr = 6 * 4;
            const ws = this.PineJS.Std.close(this._context);
            const wa = this.PineJS.Std.high(this._context);
            const wl = this.PineJS.Std.low(this._context);
            const wc = this._context.new_var(ws);
            const wu = this._context.new_var(wa);
            const wh = this._context.new_var(wl);
            const wd = this.PineJS.Std.stoch(wc, wu, wh, wi, this._context);
            const wp = this._context.new_var(wd);
            const wg = this.PineJS.Std.ema(wp, wo, this._context);
            const wf = this._context.new_var(wg);
            const wKD_K = wg;
            const wKD_K_array = this._context.new_var(wKD_K);
            const wKD_D = this.PineJS.Std.ema(wf, wr, this._context);
            const wKD_D_array = this._context.new_var(wKD_D);
            let B_signal = NaN;
            let S_signal = NaN;
            bState.get(1);
            KD_K_array.get(1);
            KD_D_array.get(1);
            wKD_K_array.get(1);
            wKD_D_array.get(1);
            if (KD_K_array.get(1) >= KD_D_array.get(1) && wKD_K_array.get(1) >= wKD_D_array.get(1)) {
                bState.set(1);
            }
            else if (KD_K_array.get(1) < KD_D_array.get(1) && wKD_K_array.get(1) < wKD_D_array.get(1)) {
                bState.set(-1);
            }
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                B_signal = 1;
                entryPrice.set(open);
                pvflag.set(0);
                state.entryPrice = Number(open);
                state.stopLossPrices = [Number(close - 30)];
                state.entryTime = Number(time);
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                S_signal = 1;
                entryPrice.set(open);
                pvflag.set(0);
                state.entryPrice = Number(-open);
                state.stopLossPrices = [Number(close + 30)];
                state.entryTime = Number(time);
            }
            let pb1 = NaN;
            let pb2 = NaN;
            let pb3 = NaN;
            let ps1 = NaN;
            let ps2 = NaN;
            let ps3 = NaN;
            const targetValue = (l1, l2, l3, l4, l5) => {
                if (interval === '1D')
                    return l1;
                if (interval === '60')
                    return l2;
                if (interval === '30')
                    return l3;
                if (interval === '15')
                    return l4;
                if (interval === '5')
                    return l5;
                if (interval === '3')
                    return l5;
                if (interval === '2')
                    return l5;
                if (interval === '1')
                    return l5;
            };
            const p1 = targetValue(0.02, 0.007, 0.005, 0.003, 0.002) ?? 0.002;
            const p2 = targetValue(0.05, 0.015, 0.012, 0.009, 0.007) ?? 0.008;
            const p3 = targetValue(0.1, 0.029, 0.023, 0.018, 0.016) ?? 0.02;
            if (bState.get(0) === 1) {
                if (pvflag.get(0) === 0 && high > entryPrice * (1 + p1)) {
                    pvflag.set(1), (pb1 = 1);
                }
                else if (pvflag.get(0) === 1 && high > entryPrice * (1 + p2)) {
                    pvflag.set(2), (pb2 = 1);
                }
                else if (pvflag.get(0) === 2 && high > entryPrice * (1 + p3)) {
                    pvflag.set(3), (pb3 = 1);
                }
            }
            else if (bState.get(0) === -1) {
                if (pvflag.get(0) === 0 && low < entryPrice * (1 - p1)) {
                    pvflag.set(1), (ps1 = 1);
                }
                else if (pvflag.get(0) === 1 && low < entryPrice * (1 - p2)) {
                    pvflag.set(2), (ps2 = 1);
                }
                else if (pvflag.get(0) === 2 && low < entryPrice * (1 - p3)) {
                    pvflag.set(3), (ps3 = 1);
                }
            }
            state.nowClose = Number(close);
            state.symbolNumber = String(_symbolNumber);
            indicatorStoreSetStateDebounced(state);
            return [B_signal, S_signal, pb1, pb2, pb3, ps1, ps2, ps3];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: 'plot_5',
                type: 'chars',
            },
            {
                id: 'plot_6',
                type: 'chars',
            },
            {
                id: 'plot_7',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    color: '#e53a40',
                    textColor: '#e53a40',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#30a9de',
                    textColor: '#30a9de',
                    transparency: 20,
                    visible: true,
                },
                plot_2: {
                    color: '#ff2200',
                    textColor: '#ff2200',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#ff2200',
                    textColor: '#ff2200',
                    transparency: 0,
                    visible: true,
                },
                plot_4: {
                    color: '#ff2200',
                    textColor: '#ff2200',
                    transparency: 0,
                    visible: true,
                },
                plot_5: {
                    color: '#00ffff',
                    textColor: '#00ffff',
                    transparency: 0,
                    visible: true,
                },
                plot_6: {
                    color: '#00ffff',
                    textColor: '#00ffff',
                    transparency: 0,
                    visible: true,
                },
                plot_7: {
                    color: '#00ffff',
                    textColor: '#00ffff',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {
                in_0: 18,
                in_1: 6,
                in_2: 3,
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '☯',
                size: 'small',
                text: '',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '☯',
                size: 'small',
                text: '',
            },
            plot_4: {
                isHidden: false,
                location: 'AboveBar',
                char: '☯',
                size: 'small',
                title: 'Shapes',
            },
            plot_5: {
                isHidden: false,
                location: 'BelowBar',
                char: '☯',
                size: 'small',
                title: 'Shapes',
            },
            plot_6: {
                isHidden: false,
                location: 'BelowBar',
                char: '☯',
                size: 'small',
                title: 'Shapes',
            },
            plot_7: {
                isHidden: false,
                location: 'BelowBar',
                char: '☯',
                size: 'small',
                title: 'Shapes',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
