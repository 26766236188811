import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 關鍵K(中)(全) */
export const all_default_mid = createIndicator({
    displayName: '關鍵K(長)', //周KD 原：中
    id: 'alldefaultmid',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const i = 45;
            const o = 30;
            const r = 30;
            const s = this.PineJS.Std.close(this._context);
            const a = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const c = this._context.new_var(s);
            const u = this._context.new_var(a);
            const h = this._context.new_var(l);
            const d = this.PineJS.Std.stoch(c, u, h, i, this._context);
            const p = this._context.new_var(d);
            const g = this.PineJS.Std.ema(p, o, this._context);
            const f = this._context.new_var(g);
            const j = this.PineJS.Std.ema(f, r, this._context);
            let red = NaN;
            let green = NaN;
            if (f - j >= 0) {
                red = f - j;
            }
            else {
                green = f - j;
            }
            return [red, green];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !0,
                    color: '#FF0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !0,
                    color: '#009900',
                },
            },
            precision: 4,
            inputs: {
                in_0: 45,
                in_1: 15,
                in_2: 15,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Signal',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Signal',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [
        /*{
          id: 'in_0',
          name: 'length',
          defval: 14,
          type: 'integer',
          min: 1,
          max: 1e4,
        },
        {
          id: 'in_1',
          name: 'smoothK',
          defval: 1,
          type: 'integer',
          min: 1,
          max: 1e4,
        },
        {
          id: 'in_2',
          name: 'smoothD',
          defval: 3,
          type: 'integer',
          min: 1,
          max: 1e4,
        },*/
        ],
        scriptIdPart: '',
    },
});
