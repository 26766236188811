import { css } from '@emotion/react';
import React, { memo, useEffect } from 'react';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { SymbolString } from '~/modules/SDK/Symbol/SymbolString';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { LeftQuoteBox, SymbolNameBox, SymbolNameString, SymbolPriceBox, SymbolClose, SymbolChange, SymbolInfoBox, SymbolInfo, SymbolValue, SymbolText, } from './QuoteBoxCss';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
export const QuoteBox = memo(function QuoteBox(props) {
    const currentSymbol = props.useChart((state) => state.symbol);
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[currentSymbol]);
    //圖表商品有變動重新訂閱
    //去除舊寫法useMount/useUnmount（20240422 因為這版面銷量不好，目前不考慮升版為signalrHooks2）
    useEffect(() => {
        useSignalrStore.getState().subscribeAdd([currentSymbol], 'ohlc');
        return () => {
            useSignalrStore.getState().subscribeRemove([currentSymbol], 'ohlc');
        };
    }, [currentSymbol]);
    const open_ = symbolInfo?.open ?? 0;
    const high_ = symbolInfo?.high ?? 0;
    const low_ = symbolInfo?.low ?? 0;
    const close_ = symbolInfo?.close ?? 0;
    const prevRef_ = symbolInfo?.prevRef ?? 0;
    const QuoteChange_ = close_ - prevRef_;
    const QuoteChangePct_ = (QuoteChange_ / prevRef_) * 100;
    const changeColor = QuoteChange_ >= 0 ? '#aa3333' : '#33aa33';
    const changeArrow = QuoteChange_ >= 0 ? '▲' : '▼';
    return (<div css={css `
        width: 100%;
        height: 100%;
      `}>
      <LeftQuoteBox>
        <SymbolNameBox>
          <SymbolNameString>
            <SymbolName symbol={currentSymbol}/>
            <SymbolString symbol={currentSymbol}/>
          </SymbolNameString>
        </SymbolNameBox>
        <SymbolPriceBox>
          <SymbolClose>{close_.toFixed(2)}</SymbolClose>
          <SymbolChange css={css `
              color: ${changeColor};
            `}>
            {changeArrow} {QuoteChange_.toFixed(2)} ({QuoteChangePct_.toFixed(2)}%)
          </SymbolChange>
        </SymbolPriceBox>
        <SymbolInfoBox>
          <SymbolInfo>
            <SymbolText>開盤</SymbolText>
            <SymbolValue>{open_.toFixed(2)}</SymbolValue>
          </SymbolInfo>
          <SymbolInfo>
            <SymbolText css={css `
                color: #ff1111;
              `}>
              最高
            </SymbolText>
            <SymbolValue>{high_.toFixed(2)}</SymbolValue>
          </SymbolInfo>
          <SymbolInfo>
            <SymbolText css={css `
                color: #00cc00;
              `}>
              最低
            </SymbolText>
            <SymbolValue>{low_.toFixed(2)}</SymbolValue>
          </SymbolInfo>
          <SymbolInfo>
            <SymbolText>收盤</SymbolText>
            <SymbolValue>{close_.toFixed(2)}</SymbolValue>
          </SymbolInfo>
        </SymbolInfoBox>
      </LeftQuoteBox>
    </div>);
});
