import { css, Global } from '@emotion/react';
import { Fragment, memo } from 'react';
import { useFavicon } from 'react-use';
import { proxy, useSnapshot } from 'valtio';
import { createDesktopCss } from '~/css/createDesktopCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { createIPadCss } from '~/css/createIPadCss';
import useMedia from '~/hooks/useMedia';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { InvestmentConsultantSubscriptionCheck } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen';
import { CurrentSymbolInfo, IndicatorsButtonName, InfoTop, QuoteBoxElement, SideQuoteCss, SwitchModeButton, SwitchModeElement, SwitchTESAButton, } from '~/pages/blue888/modules/blue168Css';
import { HoldingCostPrice } from './modules/HoldingCostPrice';
import { QuoteBox } from './modules/QuoteBox';
import { useChartOfBlue168 } from './modules/userChart';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { AddSymbolButton } from '~/modules/WatchList/AddSymbolToWatchListButton';
import { LayoutModule } from '~/modules/SDK/Layout/layout/LayoutModule';
import { meCheckHandlerAgentWeb } from '~/modules/SDK/me/meCheckHandlerAgentWeb';
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useIndicatorStore } from '~/store/useIndicatorStore';
import { fill_vertical_cross_center, flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
/* indicator */
import { all_extreme_short } from '~/trades/indicators/blue168/all_day_kd';
//import { all_default_big } from '~/trades/indicators/blue168/all_default_big'
import { all_default_mid } from '~/trades/indicators/blue168/all_default_mid';
import { all_default_short } from '~/trades/indicators/blue168/all_default_short';
import { all_crazy_b_signal } from '~/trades/indicators/blue168/all_crazy_b_signal';
import { stock_default_short } from '~/trades/indicators/blue168/stock_default_short';
import { stock_k_bar } from '~/trades/indicators/blue168/stock_k_bar';
import { stock_crazy_a_signal } from '~/trades/indicators/blue168/stock_crazy_a_signal';
import { twse_k_bar } from '~/trades/indicators/blue168/twse_k_bar';
import { twse_crazy_a_signal } from '~/trades/indicators/blue168/twse_crazy_a_signal';
import { twse_crazy_c_signal } from '~/trades/indicators/blue168/twse_crazy_c_signal';
import { all_day_rainbow } from '~/trades/indicators/blue168/all_day_rainbow';
import { paperClasses } from '@mui/material';
import { generateScrollbar } from '~/css/scrollbarCss';
import { ChartServerSelect } from '~/modules/SDK/Chart2/ChartServerSelect';
const layout1 = new LayoutModule();
const layout2 = new LayoutModule();
layout1.store.Drawer.position = 'left';
layout2.store.Drawer.position = 'right';
layout1.store.Drawer.customCSS = css `
  .${paperClasses.root} {
    width: 325px;
  }
`;
layout2.store.Drawer.customCSS = css `
  .${paperClasses.root} {
    width: 325px;
  }
`;
layout1.store.DrawerToggle.customCSS = css `
  width: 40px;
  height: 40px;
`;
layout2.store.DrawerToggle.customCSS = css `
  width: 40px;
  height: 40px;
`;
layout1.store.LeftMainRight.customCSS = css `
  grid-template-columns: 330px 1fr 330px;
`;
layout1.store.Main.customCSS = css `
  ${layout1.store.Main.defaultsCSS};
  .charting_library {
    border: 1px solid ${'#555555'};
    height: calc(100vh - 5px);
  }
`;
layout1.store.Top.customCSS = css `
  justify-content: space-between;
`;
layout1.store.Left.customCSS = css `
  ${flex.h.default};
  height: calc(100vh - 5px);
  width: 100%;
`;
layout1.store.Right.customCSS = css `
  ${flex.h.default};
  ${SideQuoteCss};
  ${jc.spaceBetween}
  height: calc(100vh - 5px);
  width: 100%;
`;
const SideBarCss = css `
  ${flex.v.crossCenter};
  width: 100%;
  height: calc(100% - 5px);
  border-radius: 5px;
  font-size: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  ${generateScrollbar('#454545', '#bbbbbb')}
`;
const store = proxy({
    groupName: 'blue168_stock_group_1',
    strategy: 'A',
    conditions: 'default',
    backendDate: '',
});
const IndicatorsA = [
    twse_k_bar,
    stock_k_bar,
    all_day_rainbow,
    twse_crazy_a_signal,
    stock_crazy_a_signal,
    all_extreme_short,
    all_default_short,
    stock_default_short,
    all_default_mid,
];
const IndicatorsB = [
    twse_k_bar,
    stock_k_bar,
    all_day_rainbow,
    all_crazy_b_signal,
    all_extreme_short,
    all_default_short,
    stock_default_short,
    all_default_mid,
];
const IndicatorsC = [
    all_day_rainbow,
    twse_crazy_c_signal,
    twse_k_bar,
    all_extreme_short,
    all_default_short,
    all_default_mid,
];
const statusColor = (status) => {
    if (status === store.conditions)
        return '#997C2C';
};
const isIndex = (symbol) => {
    return (symbol.includes('-') ||
        symbol.includes('TSEA') ||
        symbol.includes('TSE') ||
        symbol.includes('TWS') ||
        symbol.includes('OTC'));
};
const TSEASwitchbutton = memo(function TSEASwitchbutton() {
    return (<useChartOfBlue168.SymbolIndicatorsSwitch symbol={'TSEA'} indicators={[twse_k_bar]} render={symbol => (<div onClick={() => {
                useChartOfBlue168
                    .getState()
                    .widget?.activeChart()
                    .setResolution('30', function callback() {
                    // noop
                });
                // setStrategy(strategy)
            }}>
          <SwitchTESAButton>大盤強弱K</SwitchTESAButton>
        </div>)}/>);
});
const IndicatorsButton = memo(function IndicatorsButton(props) {
    return (<div css={css `
        width: 50%;
        margin: 2.5px;
        display: ${props.display === undefined
            ? 'block'
            : props.display
                ? 'block'
                : 'none!important'};
      `} onClick={() => {
            store.strategy = props.strategyState;
        }}>
      {/** 沒有C策略的商品自動跳回A策略 */}
      <useChartOfBlue168.IndicatorsSwitch indicators={props.indicators}>
        <IndicatorsButtonName css={css `
            background-color: ${props.strategyColor};
          `}>
          {props.titleText}
        </IndicatorsButtonName>
      </useChartOfBlue168.IndicatorsSwitch>
    </div>);
});
const IndicatorsSwitchGroup = memo(function IndicatorsSwitchGroup() {
    const state = useSnapshot(store);
    const _symbolNumber = useChartOfBlue168(state_ => state_.symbol);
    const _symbolChannel = useIndicatorStore(state_ => state_.symbolChannel) || '';
    /** 符合策略C的商品(台指、海期、加權) */
    const ConditionStrategyC = _symbolChannel === 'os_futures' ||
        _symbolNumber.includes('TSEA') ||
        _symbolNumber.includes('TX') ||
        _symbolNumber.includes('-');
    return (<div css={flex.h.default}>
      <IndicatorsButton indicators={IndicatorsA} titleText='狂飆A' strategyState='A' strategyColor={state.strategy === 'A' ? '#997c2c' : '#555555'}/>

      <IndicatorsButton indicators={IndicatorsB} titleText='狂飆B' strategyState='B' strategyColor={state.strategy === 'B' ? '#997c2c' : '#555555'}/>
      <IndicatorsButton indicators={IndicatorsC} titleText='狂飆C' strategyState='C' strategyColor={state.strategy === 'C' ? '#997c2c' : '#555555'} display={ConditionStrategyC}/>
    </div>);
});
const Logo = memo(function Logo() {
    return (<div css={css `
        ${fill_vertical_cross_center}
        justify-content: end;
        height: calc(100% - 400px);
      `}>
      <img alt='logo' css={css `
          ${createMobileCss2(css `
            width: 0px;
            height: 0px;
          `)}
          ${createDesktopCss(css `
            max-width: 100%;
            max-height: 100%;
          `)}
          ${createIPadCss(css `
            max-width: 100%;
            /* max-height: 100%; */
          `)}
        `} src='blue168/favicon.png'/>
    </div>);
});
const LeftContent = memo(function LeftContent() {
    const state = useSnapshot(store);
    const _symbolNumber = useChartOfBlue168(state_ => state_.symbol);
    const popularData = usePopularPick({ date: state.backendDate, sort: 'volume', limit: 25 });
    const popularSymbol = popularData.resultSymbol?.filter(item => item.length < 5);
    /** 沒有C策略的商品強制返回A策略狀態 */
    const resetStrategyA = () => {
        if (state.strategy === 'C') {
            store.strategy = 'A';
        }
    };
    /**
     * 股票商品 可以使用 A B 策略 期貨商品 可以使用 A B C 策略
     *
     * 情境：當下為期貨商品且策略在A,切至股票商品 策略要維持在A （反之股票切換期貨也是同邏輯） 情境：當下為期貨商品且策略在B,切至股票商品 策略要維持在B （反之股票切換期貨也是同邏輯）
     * 情境：當下為期貨商品且策略在C,切至股票商品 策略要切換成在A 情境：當下為期貨商品且策略在C,切至期貨商品 策略要維持在C (測試中)
     */
    /** 根據指標狀態 在點選商品時要返回的指標組 */
    const IndicatorsInChart = () => {
        if (state.strategy === 'A') {
            return IndicatorsA;
        }
        if (state.strategy === 'B') {
            return IndicatorsB;
        }
        /** 當前策略為C 切換成股票時,將策略強制切回A */
        if (state.strategy === 'C' && isIndex(_symbolNumber)) {
            return IndicatorsA;
        }
        if (state.strategy === 'C') {
            return IndicatorsC;
        }
    };
    return (<div css={css `
        width: 100%;
        height: calc(100% - 64px);
      `}>
      <QuoteBoxElement>
        <QuoteBox useChart={useChartOfBlue168}/>
      </QuoteBoxElement>
      <div css={css `
          height: 30px;
        `}>
        <TSEASwitchbutton />
      </div>
      <SwitchModeElement>
        <SwitchModeButton onClick={() => {
            store.conditions = 'default';
        }} color={statusColor('default')}>
          熱門股
        </SwitchModeButton>
        <SwitchModeButton onClick={() => {
            store.conditions = 'userStock';
        }} color={statusColor('userStock')}>
          自選股
        </SwitchModeButton>
      </SwitchModeElement>
      <div>
        <AddSymbolButton groupName={state.groupName} symbolNumber={_symbolNumber} listSize={20}/>
      </div>
      <div css={css `
          width: 100%;
          height: calc(100% - 280px);
        `}>
        <div css={css `
            width: 100%;
            height: calc(100% - 5px);
            display: ${state.conditions === 'userStock' ? 'block' : 'none!important'};
          `}>
          <WatchListWithChart groupName={state.groupName} useChart={useChartOfBlue168} handleClick={() => resetStrategyA()} indicators={IndicatorsInChart() ?? []}/>
        </div>
        <div css={css `
            ${SideBarCss};
            display: ${state.conditions === 'default' ? 'block' : 'none!important'};
          `}>
          <SimpleQuoteListInteractWithChart data={popularSymbol ?? []} chart={useChartOfBlue168} handleClick={() => resetStrategyA()} indicators={IndicatorsInChart()}/>
        </div>
        <div css={css `
            ${flex.h.mainCenter};
            padding: 4px;
          `}>
          <UserAvatarAsDialogButton />
        </div>
      </div>
    </div>);
});
export const RightContent = memo(function RightContent(props) {
    const state = useSnapshot(store);
    const _symbolNumber = useChartOfBlue168(state_ => state_.symbol);
    /** 顯示邏輯 */
    const modeTextColor = isIndex(_symbolNumber)
        ? 'background-color: #1D3F54'
        : 'background-color: #541D1D';
    /** 顯示邏輯 */
    const modeText = isIndex(_symbolNumber) ? '指數策略' : '股票策略';
    /** 圖表內切換商品時強制切換至Ａ策略 */
    if (state.strategy === 'C' && !isIndex(_symbolNumber)) {
        store.strategy = 'A';
        useChartOfBlue168.getState().changeIndicators(IndicatorsA);
    }
    return (<layout1.Right>
      <div>
        <div>
          <ChartServerSelect useChart={useChartOfBlue168}/>
        </div>
        <InfoTop css={css `
            ${modeTextColor}
          `}>
          {modeText}
          {state.strategy}
        </InfoTop>

        <IndicatorsSwitchGroup />

        <CurrentSymbolInfo>
          <useChartOfBlue168.Symbol />
          <useChartOfBlue168.SymbolString />
        </CurrentSymbolInfo>

        <HoldingCostPrice />
      </div>

      <Logo />
    </layout1.Right>);
});
export default function Blue168NextPage() {
    useFavicon('/blue168/favicon.png');
    const { isPc, isPhone } = useMedia();
    const legitUser = meCheckHandlerAgentWeb.useCheck();
    useSignalrBuild({ reconnectCheckInterval: 10000 });
    useSignalrStoreValueOHLC.getState().useValueUpdateInterval(500);
    useSignalrStart2_1();
    useThemeStore.setState({ theme: 'dark' });
    if (!legitUser) {
        return <InvestmentConsultantSubscriptionCheck />;
    }
    return (<Fragment>
      <Global styles={css `
          html,
          body,
          #__next {
            background-color: #1d1d1d;
          }
        `}></Global>
      <layout1.LeftMainRight>
        {!isPc && (<Fragment>
            <layout1.Drawer>
              <LeftContent />
            </layout1.Drawer>

            <layout2.Drawer>
              <RightContent />
            </layout2.Drawer>
          </Fragment>)}

        {!isPc && (<layout1.Top>
            <layout1.DrawerToggle></layout1.DrawerToggle>
            <layout2.DrawerToggle></layout2.DrawerToggle>
          </layout1.Top>)}

        {isPc && (<layout1.Left>
            <LeftContent />
          </layout1.Left>)}

        <layout1.Main>
          <useChartOfBlue168.Chart />
        </layout1.Main>

        {isPc && (<layout1.Right>
            <RightContent />
          </layout1.Right>)}
      </layout1.LeftMainRight>
    </Fragment>);
}
