import { useIndicatorStore, indicatorStoreSetStateDebounced } from '~/store/useIndicatorStore';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 狂飆B訊號 */
export const all_crazy_b_signal = createIndicator({
    displayName: '狂飆B訊號',
    id: 'allkdsignal',
    enabledOn(symbol, data, channel) {
        const state = useIndicatorStore.getState();
        state.symbolChannel = String(data?.type);
        indicatorStoreSetStateDebounced(state);
        return symbol.includes('');
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const in1 = this._input(0);
            const in2 = this._input(1);
            const time = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const close_array = this._context.new_var(close);
            const bState = this._context.new_var();
            const bsentry = this._context.new_var();
            const entryPrice = this._context.new_var();
            const _symbolNumber = this.PineJS.Std.ticker(this._context);
            //日線KD -------------------
            const i = 9;
            const o = 6;
            const r = 6;
            const s = this.PineJS.Std.close(this._context);
            const a = this.PineJS.Std.high(this._context);
            const l = this.PineJS.Std.low(this._context);
            const c = this._context.new_var(s);
            const u = this._context.new_var(a);
            const h = this._context.new_var(l);
            const d = this.PineJS.Std.stoch(c, u, h, i, this._context);
            const p = this._context.new_var(d);
            const g = this.PineJS.Std.ema(p, o, this._context);
            const f = this._context.new_var(g);
            const KD_K = g;
            const KD_K_array = this._context.new_var(KD_K);
            const KD_D = this.PineJS.Std.ema(f, r, this._context);
            const KD_D_array = this._context.new_var(KD_D);
            //周線KD -------------------
            const wi = 9 * 5;
            const wo = 6 * 5;
            const wr = 6 * 5;
            const ws = this.PineJS.Std.close(this._context);
            const wa = this.PineJS.Std.high(this._context);
            const wl = this.PineJS.Std.low(this._context);
            const wc = this._context.new_var(ws);
            const wu = this._context.new_var(wa);
            const wh = this._context.new_var(wl);
            const wd = this.PineJS.Std.stoch(wc, wu, wh, wi, this._context);
            const wp = this._context.new_var(wd);
            const wg = this.PineJS.Std.ema(wp, wo, this._context);
            const wf = this._context.new_var(wg);
            const wKD_K = wg;
            const wKD_K_array = this._context.new_var(wKD_K);
            const wKD_D = this.PineJS.Std.ema(wf, wr, this._context);
            const wKD_D_array = this._context.new_var(wKD_D);
            let B_signal = NaN;
            let S_signal = NaN;
            bState.get(1);
            KD_K_array.get(1);
            KD_D_array.get(1);
            wKD_K_array.get(1);
            wKD_D_array.get(1);
            if (KD_K_array.get(1) >= KD_D_array.get(1) && wKD_K_array.get(1) >= wKD_D_array.get(1)) {
                bState.set(1);
            }
            else if (KD_K_array.get(1) < KD_D_array.get(1) && wKD_K_array.get(1) < wKD_D_array.get(1)) {
                bState.set(-1);
            }
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                B_signal = 1;
                entryPrice.set(open);
                state.entryPrice = Number(open);
                state.stopLossPrices = [Number(close - 30)];
                state.entryTime = Number(time);
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                S_signal = 1;
                entryPrice.set(open);
                state.entryPrice = Number(-open);
                state.stopLossPrices = [Number(close + 30)];
                state.entryTime = Number(time);
            }
            state.nowClose = Number(close);
            state.symbolNumber = String(_symbolNumber);
            indicatorStoreSetStateDebounced(state);
            return [B_signal, S_signal];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    color: '#e53a40',
                    textColor: '#e53a40',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#30a9de',
                    textColor: '#30a9de',
                    transparency: 20,
                    visible: true,
                },
            },
            inputs: {
                in_0: 9,
                in_1: 3,
                in_2: 3,
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '',
            },
        },
        is_price_study: !0,
        inputs: [
        /*{
          id: 'in_0',
          name: 'length',
          defval: 9,
          type: 'integer',
          min: 1,
          max: 1e4,
        },
        {
          id: 'in_1',
          name: 'smoothK',
          defval: 3,
          type: 'integer',
          min: 1,
          max: 1e4,
        },
        {
          id: 'in_2',
          name: 'smoothD',
          defval: 3,
          type: 'integer',
          min: 1,
          max: 1e4,
        },*/
        ],
        scriptIdPart: '',
    },
});
