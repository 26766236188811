import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 多空分界 */
export const twse_k_bar = createIndicator({
    displayName: '多空分界',
    id: 'twsekbar',
    enabledOn(symbol, data, channel) {
        return (symbol.includes('TSEA') ||
            symbol.includes('TSE') ||
            symbol.includes('TWS') ||
            symbol.includes('OTC') ||
            symbol.includes('-'));
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const interval = this.PineJS.Std.interval(this._context);
            if (interval !== 30) {
                const in1 = 207;
                const in2 = 111;
                const close = this.PineJS.Std.close(this._context);
                const close_array = this._context.new_var(close);
                const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
                const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
                const shortStrong = close > sma1 && close > sma2;
                const longWeak = close > sma1 && close < sma2;
                const shortWeak = close < sma1 && close > sma2;
                const longStrong = close < sma1 && close < sma2;
                const colorIndex = shortStrong ? 0 : longWeak ? 1 : shortWeak ? 2 : 3;
                return [colorIndex];
            }
            if (interval === 30) {
                const in1 = 33;
                const close = this.PineJS.Std.close(this._context);
                const close_array = this._context.new_var(close);
                const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
                const shortStrong = close > sma1;
                const colorIndex = shortStrong ? 0 : 3;
                return [colorIndex];
            }
        },
    },
    metainfo: {
        plots: [
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        defaults: {
            //樣式調整
            inputs: {
                in_0: 207,
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#ec407a',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#aa0000',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#007700',
                        width: 1,
                        style: 0,
                    },
                    3: {
                        color: '#00ee00',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        is_price_study: !0,
        inputs: [
        /*{
          id: 'in_0',
          name: 'length',
          defval: 207,
          type: 'integer',
          min: 1,
          max: 1e4,
        },*/
        ],
        scriptIdPart: '',
    },
});
