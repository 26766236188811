import { createChartStore } from '~/modules/SDK/Chart2/createChartStore';
import { twse_k_bar } from '~/trades/indicators/blue168/twse_k_bar';
import { stock_k_bar } from '~/trades/indicators/blue168/stock_k_bar';
import { all_day_rainbow } from '~/trades/indicators/blue168/all_day_rainbow';
import { twse_crazy_a_signal } from '~/trades/indicators/blue168/twse_crazy_a_signal';
import { stock_crazy_a_signal } from '~/trades/indicators/blue168/stock_crazy_a_signal';
import { all_crazy_b_signal } from '~/trades/indicators/blue168/all_crazy_b_signal';
import { twse_crazy_c_signal } from '~/trades/indicators/blue168/twse_crazy_c_signal';
import { all_default_mid } from '~/trades/indicators/blue168/all_default_mid';
import { stock_default_short } from '~/trades/indicators/blue168/stock_default_short';
import { all_default_short } from '~/trades/indicators/blue168/all_default_short';
import { all_extreme_short } from '~/trades/indicators/blue168/all_day_kd';
//import { all_default_big } from '~/trades/indicators/blue168/all_default_big'
const preparedIndicators = [
    twse_k_bar,
    all_day_rainbow,
    stock_k_bar,
    twse_crazy_a_signal,
    stock_crazy_a_signal,
    twse_crazy_c_signal,
    all_crazy_b_signal,
    all_default_mid,
    stock_default_short,
    all_default_short,
    all_extreme_short,
];
export const useChartOfBlue168 = createChartStore({
    preparedCustomIndicators: preparedIndicators,
    defaultsOptions: {
        customIndicators: [
            twse_k_bar,
            stock_k_bar,
            all_day_rainbow,
            twse_crazy_a_signal,
            stock_crazy_a_signal,
            all_extreme_short,
            all_default_short,
            stock_default_short,
            all_default_mid,
        ],
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        symbol: 'TSEA',
        interval: '1D',
        client_id: 'blue168',
        overrides: {
            'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
            'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
            'mainSeriesProperties.candleStyle.downColor': '#00ff00',
            'mainSeriesProperties.candleStyle.upColor': '#ff0000',
            'mainSeriesProperties.candleStyle.wickDownColor': '#aaaaaa',
            'mainSeriesProperties.candleStyle.wickUpColor': '#aaaaaa',
            'mainSeriesProperties.candleStyle.drawBorder': false,
            'mainSeriesProperties.showPriceLine': false,
            'mainSeriesProperties.showCountdown': true,
            'paneProperties.horzGridProperties.style': 1,
            'paneProperties.vertGridProperties.style': 1,
            'paneProperties.topMargin': 15,
            'paneProperties.bottomMargin': 15,
            'paneProperties.vertGridProperties.color': '#444444',
            'paneProperties.horzGridProperties.color': '#444444',
            'paneProperties.background': '#111111',
            //'paneProperties.legendProperties.showSeriesOHLC': false,
            //'paneProperties.legendProperties.showBarChange': false,
            //'paneProperties.legendProperties.showStudyTitles': false,
            //'paneProperties.legendProperties.showStudyValues': false,
            //'paneProperties.legendProperties.showSeriesTitle': false,
            //'paneProperties.legendProperties.showLegend': false,
            'timeScale.rightOffset': 0,
            'scalesProperties.fontSize': 14,
            'scalesProperties.textColor': '#999999',
            'scalesProperties.lineColor': '#999999',
        },
    },
});
