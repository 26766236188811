import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 關鍵K(短)(股) */
export const stock_default_short = createIndicator({
    displayName: '關鍵K(中)', //原：短
    id: 'stockdefaultshort',
    enabledOn(symbol, data, channel) {
        return /\d/.test(symbol) && !symbol.includes('-') && !symbol.includes('T');
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const i = this.PineJS.Std.close(this._context);
            const o = 12;
            const r = 26;
            const s = 9;
            const a = this._context.new_var(i);
            const l = this.PineJS.Std.ema(a, o, this._context);
            const c = this._context.new_var(i);
            const u = this.PineJS.Std.ema(c, r, this._context);
            const h = l - u;
            const d = this._context.new_var(h);
            const p = this.PineJS.Std.ema(d, s, this._context);
            let red = NaN;
            let green = NaN;
            if (h - p >= 0) {
                red = h - p;
            }
            else {
                green = h - p;
            }
            return [red, green];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !0,
                    color: '#FF0000',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 1,
                    trackPrice: !1,
                    transparency: 35,
                    visible: !0,
                    color: '#009900',
                },
            },
            precision: 4,
            inputs: {
                in_0: 12,
                in_1: 26,
                in_3: 'close',
                in_2: 9,
            },
        },
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
        ],
        styles: {
            plot_0: {
                title: 'Histogram',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: 'Histogram',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [
        /*{
          id: 'in_0',
          name: 'fastLength',
          defval: 12,
          type: 'integer',
          min: 1,
          max: 2e3,
        },
        {
          id: 'in_1',
          name: 'slowLength',
          defval: 26,
          type: 'integer',
          min: 1,
          max: 2e3,
        },
        {
          id: 'in_3',
        },
        {
          id: 'in_2',
          name: 'signalLength',
          defval: 9,
          type: 'integer',
          min: 1,
          max: 50,
        },*/
        ],
        is_price_study: !1,
        scriptIdPart: '',
    },
});
