import { useIndicatorStore, indicatorStoreSetStateDebounced } from '~/store/useIndicatorStore';
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 狂飆A訊號(股票) */
export const stock_crazy_a_signal = createIndicator({
    displayName: '狂飆A訊號',
    id: 'stockmasignal',
    enabledOn(symbol, data, channel) {
        const state = useIndicatorStore.getState();
        state.symbolChannel = String(data?.type);
        indicatorStoreSetStateDebounced(state);
        /**訊號只能用在股票 */
        return /\d/.test(symbol) && !symbol.includes('-') && !symbol.includes('T');
    },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const state = useIndicatorStore.getState();
            const in1 = 10;
            const in2 = 20;
            const time = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const close_array = this._context.new_var(close);
            const bState = this._context.new_var();
            const bsentry = this._context.new_var();
            const entryPrice = this._context.new_var();
            const _symbolNumber = this.PineJS.Std.ticker(this._context);
            let B_signal = NaN;
            let S_signal = NaN;
            bState.get(1);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const sma1_array = this._context.new_var(sma1);
            const sma2_array = this._context.new_var(sma2);
            sma1_array.get(1);
            sma2_array.get(1);
            if (sma1_array.get(1) >= sma2_array.get(1)) {
                bState.set(1);
            }
            else if (sma1_array.get(1) < sma2_array.get(1)) {
                bState.set(-1);
            }
            if (bState.get(0) === 1 && bState.get(1) !== 1) {
                B_signal = 1;
                entryPrice.set(open);
                state.entryPrice = Number(open);
                state.stopLossPrices = [Number(close - 30)];
                state.entryTime = Number(time);
            }
            else if (bState.get(0) === -1 && bState.get(1) !== -1) {
                S_signal = 1;
                entryPrice.set(open);
                state.entryPrice = Number(-open);
                state.stopLossPrices = [Number(close + 30)];
                state.entryTime = Number(time);
            }
            state.nowClose = Number(close);
            state.symbolNumber = String(_symbolNumber);
            indicatorStoreSetStateDebounced(state);
            return [B_signal, S_signal];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    color: '#ff79ff',
                    textColor: '#ff79ff',
                    transparency: 20,
                    visible: true,
                },
                plot_1: {
                    color: '#ffffff',
                    textColor: '#ffffff',
                    transparency: 20,
                    visible: true,
                },
            },
            inputs: {
                in_0: 10,
                in_1: 23,
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: '',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: '',
            },
        },
        is_price_study: !0,
        inputs: [
        /*{
          id: 'in_0',
          name: '預設10日短均線',
          defval: 10,
          type: 'integer',
          min: 1,
          max: 1e4,
        },
        {
          id: 'in_1',
          name: '預設月均線',
          defval: 20,
          type: 'integer',
          min: 1,
          max: 1e4,
        },*/
        ],
        scriptIdPart: '',
    },
});
