import styled from '@emotion/styled';
import { flex } from '~/modules/AppLayout/FlexGridCss';
export const LeftQuoteBox = styled.div `
  ${flex.v.default};
  width: 100%;
  height: 180px;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  color: '#ffffff';
  background-color: #111111;
`;
export const SymbolNameBox = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 20%;
  border-radius: 5px 5px 0px 0px;
  background-color: #212121;
  font-size: 26px;
  color: '#ffffff';
`;
export const SymbolPriceBox = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 37.5%;
  margin-left: 2.5%;
  font-size: 26px;
  color: '#ffffff';
`;
export const SymbolInfoBox = styled.div `
  ${flex.inline.allCenter};
  margin-top: 5px;
  width: 100%;
  height: 42.5%;
`;
export const SymbolNameString = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 60%;
  color: '#ffffff';
  border-radius: 5px;
  font-size: 26px;
`;
export const SymbolInfo = styled.div `
  ${flex.v.crossCenter};
  width: 25%;
  height: 100%;
  margin: 2.5px;
  border-radius: 5px;
  background-color: #444444;
`;
export const SymbolClose = styled.div `
  ${flex.h.allCenter};
  width: 40%;
  height: 100%;
  font-size: 26px;
`;
export const SymbolChange = styled.div `
  ${flex.h.allCenter};
  width: 60%;
  height: 100%;
  font-size: 20px;
`;
export const SymbolText = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  font-size: 16px;
  border-radius: 5px 5px 0px 0px;
  background-color: #222222;
  color: #ffffff;
`;
export const SymbolValue = styled.div `
  ${flex.h.allCenter};
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #ffffff;
`;
